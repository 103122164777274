<template>
  <div class="article">
    <div class="form">
       <el-form :inline="true" size="small">
        <el-form-item label="标题">
          <el-input v-model="queryInfo.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="queryInfo.description" placeholder="请输入描述" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getArticleList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btns">
        <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        <el-button size="small" type="primary" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 240px)"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column label="栏目" prop="category" />
      <el-table-column label="封面" width="100">
        <template slot-scope="scope">
          <el-image
            class="cover"
            :src="scope.row.cover"
            :preview-src-list="[scope.row.cover]"
            style="margin-right: 5px"
          />
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title" show-overflow-tooltip />
      <el-table-column label="描述" prop="description" show-overflow-tooltip />
      <el-table-column label="外链">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.url">已设置</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="关键词" prop="keywords" show-overflow-tooltip />
      <el-table-column label="排序" prop="sort" />
      <el-table-column label="创建时间" prop="created_at" width="180" />
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 模态 -->
    <el-dialog :title="formTitle" width="70%" :visible.sync="articleFormVisible">
      <el-form
        :model="articleForm"
        :rules="articleRules"
        ref="articleForm"
        size="medium"
        label-width="80px"
      >
        <el-form-item label="栏目" prop="category_id">
          <el-select v-model="articleForm.category_id" placeholder="请选择栏目">
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封面" prop="cover">
          <div class="content">
            <el-image
              v-if="articleForm.cover"
              class="cover"
              :src="articleForm.cover"
              :preview-src-list="[articleForm.cover]"
            />
            <image-upload
              style="width: 50px; height: 50px"
              dir="product"
              :play-back="false"
              @input="handleImageInput"
            />
          </div>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="articleForm.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="articleForm.description" placeholder="请输入描述" />
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <quill-editor
            v-model="articleForm.content"
            :options="editorOption"
            ref="myQuillEditor"
          />
        </el-form-item>
        <el-form-item label="关键词" prop="keywords">
          <el-input v-model="articleForm.keywords" placeholder="请输入关键词. 多个关键词用 ',' 隔开" />
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="articleForm.sort" :min="0" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { getCategoryList } from '@/services/category'
import {
  getArticleList,
  deleteArticle,
  addArticle,
  updateArticle,
  exportAll
} from '@/services/article'

Quill.register('modules/ImageExtend', ImageExtend)

export default {
  name: 'Article',
  components: {
    quillEditor
  },
  data () {
    return {
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      articleFormVisible: false,
      categoryOptions: [],
      articleForm: {
        sort: 0
      },
      articleRules: {
        category_id: [
          { required: true, message: '请选择栏目', trigger: 'blur' }
        ],
        cover: [
          { required: true, message: '请上传封面', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ]
      },
      formTitle: '新增',
      formType: 'add',
      editorOption: {
        placeholder: '请输入内容...',
        modules: {
          ImageExtend: {
            loading: true,
            name: 'img',
            action: `${process.env.VUE_APP_SERVER}/api/v1/third/image-to-cos`,
            headers: (xhr) => {
              xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.user.access_token}`)
            },
            response: (res) => {
              return `${process.env.VUE_APP_COS_URL}/${res.key}`
            }
          },
          toolbar: {
            container: container,
            handlers: {
              image () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    }
  },
  created () {
    this.getCategoryList()
    this.getArticleList()
  },
  methods: {
    async getCategoryList () {
      try {
        const { data } = await getCategoryList()
        const arr = data.data
        arr.forEach(item => {
          const obj = {
            label: item.title,
            value: item.id
          }
          this.categoryOptions.push(obj)
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getArticleList () {
      try {
        const { data } = await getArticleList(this.queryInfo)
        this.tableData = data.data
        this.total = data.meta.total
      } catch (e) {
        console.log(e)
      }
    },
    reset () {
      this.queryInfo = {
        pageNum: 1,
        pageSize: 10
      }
      this.getArticleList()
    },
    handleAdd () {
      this.formTitle = '新增'
      this.formType = 'add'
      this.articleFormVisible = true
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getArticleList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getArticleList()
    },
    handleImageInput (url) {
      this.$set(this.articleForm, 'cover', url)
    },
    handleEdit (index, row) {
      if (this.$refs.articleForm) {
        this.$refs.articleForm.resetFields()
      }
      this.formTitle = '编辑'
      this.formType = 'edit'
      this.articleForm = { ...row }
      this.articleFormVisible = true
    },
    cancel () {
      this.articleForm = {
        sort: 0
      }
      this.articleFormVisible = false
    },
    save () {
      this.$refs.articleForm.validate(async valid => {
        if (valid) {
          try {
            const formData = { ...this.articleForm }
            this.formType === 'add' ? await addArticle(formData) : await updateArticle(formData.id, formData)
            delete this.articleForm.cover
            this.articleForm = { sort: 0 }
            this.articleFormVisible = false
            this.getArticleList()
            this.$message({
              type: 'success',
              message: `${this.formTitle}文章成功`
            })
          } catch (err) {
            this.$message({
              type: 'error',
              message: `${this.formTitle}文章失败`
            })
          }
        }
      })
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteArticle(row.id)
        this.getArticleList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `文章-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss" scoped>
.article {
  box-sizing: border-box;
  padding: 15px;
  .form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 15px 0;
    .el-form .el-form-item {
      margin-bottom: 0;
    }
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
  .cover {
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
  }
  .el-dialog {
    .content {
      display: flex;
      flex-wrap: wrap;
      .preview-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        width: 50px;
      }
    }
  }
  ::v-deep .ql-container {
    height: 400px;
  }
}
</style>
