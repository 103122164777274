import request from '@/utils/request'
import qs from 'qs'

// 列表
export const getCategoryList = params => {
  return request({
    method: 'GET',
    url: '/categories',
    params
  })
}

// 编辑
export const updateCategory = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/categories/${id}`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}
