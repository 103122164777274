import request from '@/utils/request'
import qs from 'qs'

// 列表
export const getArticleList = params => {
  return request({
    method: 'GET',
    url: '/articles',
    params
  })
}

// 删除
export const deleteArticle = id => {
  return request({
    method: 'DELETE',
    url: `/articles/${id}`
  })
}

// 新增
export const addArticle = data => {
  return request({
    method: 'POST',
    url: '/articles',
    data
  })
}

// 编辑
export const updateArticle = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/articles/${id}`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 导出
export const exportAll = () => {
  return request({
    method: 'POST',
    url: '/articles/export',
    responseType: 'blob'
  })
}
